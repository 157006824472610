import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import './events';
import socket from "./socket"
import { eventList } from "./event-list"

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();

// Escuchar eventos del servidor soketio y lanzar un emiter
const eventHandler = (data: any, eventName: string) => {
  data = JSON.parse(data);
  window.globalEventEmitter.emit(data.chanel, data);
};
// Escuchar eventos del servidor
eventList.forEach((eventName) => {
  socket.on(eventName, (data: any) => eventHandler(data, eventName));
});

start();
