import { io, Socket } from "socket.io-client";

// Reemplaza la URL con la dirección del servidor Socket.IO
// const socketUrl = "https://koffeesenser-qa.agriculturapp.com.co"; // Servidor
const socketUrl = "https://koffeesenser-qa.agriculturapp.com.co"; // Servidor
// const socketUrl = "http://localhost:3003"; // local

// Configura el cliente de Socket.IO
const socket: Socket = io(socketUrl);

export default socket;
