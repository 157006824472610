import { EventEmitter } from "events";

declare global {
  interface Window {
    globalEventEmitter: EventEmitter;
  }
}

if (!window.globalEventEmitter) {
  window.globalEventEmitter = new EventEmitter();
}